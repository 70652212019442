import * as _ from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import numeral from 'numeral';

const AmountFormat = '$ 0,0';
const NumberFormat = '0a';
const PercentFormat = '0,0.00%';

dayjs.extend(isBetween);

const hasStatistics = {
  data() {
    return {
      movement: 0,
    };
  },
  computed: {
    donationsCountPerHour() {
      if (this.donations) {
        // collect all the donations this past hour
        const hourStart = dayjs().startOf('hour');
        const hourEnd = dayjs(hourStart).add(1, 'hour');
        const donations = this.donations.filter((d) => {
          return dayjs(d.created_at).isBetween(hourStart, hourEnd);
        });

        return donations.length ?? 0;
      }

      if (this.statistics) {
        return this.statistics.donations_per_hour;
      }

      return 0;
    },
    donationsCountPerHourSingle() {
      if (this.donations) {
        // collect all the donations this past hour
        const hourStart = dayjs().startOf('hour');
        const hourEnd = dayjs(hourStart).add(1, 'hour');
        const donations = this.donations.filter((d) => {
          return d.recurring == false && dayjs(d.created_at).isBetween(hourStart, hourEnd);
        });

        return donations.length ?? 0;
      }

      if (this.statistics) {
        return this.statistics.donations_single_per_hour;
      }

      return 0;
    },
    donationsCountPerHourRecurring() {
      if (this.donations) {
        // collect all the donations this past hour
        const hourStart = dayjs().startOf('hour');
        const hourEnd = dayjs(hourStart).add(1, 'hours');
        const donations = this.donations.filter((d) => {
          return d.recurring == true && dayjs(d.created_at).isBetween(hourStart, hourEnd);
        });

        return donations.length ?? 0;
      }

      if (this.statistics) {
        return this.statistics.donations_recurring_per_hour;
      }

      return 0;
    },
    donationsPerDay() {
      if (this.statistics) {
        return this.statistics.total_donations_current_day_sum;
      }

      if (this.donations) {
        // collect all the donations this past day
        const dayStart = dayjs().startOf('day');
        const dayEnd = dayjs(dayStart).add(1, 'day');
        const donations = this.donations.filter(this.filterOldRecurring).filter((d) => {
          return dayjs(d.created_at).isBetween(dayStart, dayEnd);
        });

        return _.sumBy(donations, (d) => d.total);
      }

      return 0;
    },
    donationsPerHour() {
      if (this.statistics) {
        return this.statistics.total_donations_current_hour_sum;
      }

      if (this.donations) {
        // collect all the donations this past hour
        const hourStart = dayjs().startOf('hour');
        const hourEnd = dayjs(hourStart).add(1, 'hour');
        const donations = this.donations.filter(this.filterOldRecurring).filter((d) => {
          return dayjs(d.created_at).isBetween(hourStart, hourEnd);
        });

        return _.sumBy(donations, (d) => d.total);
      }

      return 0;
    },
    donationsPerHourSingle() {
      // console.log('hasStatistics computed donationsPerHourSingle()');
      if (this.statistics) {
        // console.log('hasStatistics computed donationsPerHourSingle() no donations');
        return this.statistics.total_donations_single_current_hour_sum;
      }

      if (this.donations) {
        // collect all the donations this past hour
        const hourStart = dayjs().startOf('hour');
        const hourEnd = dayjs(hourStart).add(1, 'hour');
        const donations = this.donationsSingle.filter((d) => {
          return d.recurring == false && dayjs(d.created_at).isBetween(hourStart, hourEnd);
        });

        return _.sumBy(donations, (d) => d.total);
      }

      return 0;
    },
    donationsPerHourRecurring() {
      // console.log('hasStatistics computed donationsPerHourRecurring()');
      if (this.statistics) {
        // console.log('hasStatistics computed donationsPerHourRecurring() no donations');
        return this.statistics.total_donations_recurring_current_hour_sum;
      }

      if (this.donations) {
        // collect all the donations this past hour
        const hourStart = dayjs().startOf('hour');
        const hourEnd = dayjs(hourStart).add(1, 'hour');
        const donations = this.donationsRecurring.filter((d) => {
          return d.recurring == true && dayjs(d.created_at).isBetween(hourStart, hourEnd);
        });

        return _.sumBy(donations, (d) => d.total * 12);
      }

      return 0;
    },
    totalDollars() {
      // Use statistics
      if (this.statistics) {
        // console.log('hasStatistics.js computed totalDollars via statistics');
        return Number(this.statistics.total_sum) + Number(this.statistics.pledges_total_sum);
      } else if (this.donations) {
        // console.log('hasStatistics.js computed totalDollars :donations', this.donations);
        const donations = _.sumBy(this.donations, (d) => (d.recurring ? d.total * 12 : d.total));

        if (this.pledges) {
          // console.log('hasStatistics.js computed totalDollars :pledges', this.pledges);
          const pledges = _.sumBy(this.pledges, (p) => p.total);
          return donations + pledges;
        }

        return donations;
      }

      return 0;
    },
    totalDollarsSingle() {
      if (!this.statistics) {
        // If we have donations, use that first to count
        if (this.donations) {
          return _.sumBy(this.donationsSingle, (d) => d.total);
        } else {
          return 0;
        }
      }

      // Use statistics
      return Number(this.statistics.total_single_sum) + Number(this.statistics.pledges_total_sum);
    },
    totalDollarsTrackedRecurring() {
      const min = this.intentionMeta.tracked_donation_minimum;
      const trackedDonations = this.donations.filter((d) => d.amount >= min && d.recurring == true);
      return _.sumBy(trackedDonations, (d) => d.total);
    },
    totalDollarsTrackedSingle() {
      const min = this.intentionMeta.tracked_donation_minimum;
      const trackedDonations = this.donations.filter(
        (d) => d.amount >= min && d.recurring == false
      );
      return _.sumBy(trackedDonations, (d) => d.total);
    },
    totalDollarsRecurring() {
      if (!this.statistics) {
        // If we have donations, use that first to count
        if (this.donations) {
          return _.sumBy(this.donationsRecurring, (d) => d.total * 12);
        } else {
          return 0;
        }
      }

      // Use statistics
      return Number(this.statistics.total_recurring_sum);
    },
    totalDonations() {
      // if we have statistics, use that
      if (this.statistics) {
        return Number(this.statistics.donations_count);
      }

      // If we have donations, use that first
      if (this.donations) {
        return this.donations.length;
      }

      return 0;
    },
    totalDonationsSingle() {
      // if we have statistics, use that
      if (this.statistics) {
        return (
          Number(this.statistics.donations_single_count) + Number(this.statistics.pledges_count)
        );
      }

      // If we have donations, use that
      if (this.donationsSingle) {
        return this.donationsSingle.length;
      }

      return 0;
    },
    totalDonationsRecurring() {
      // if we have statistics, use that
      if (this.statistics) {
        return Number(this.statistics.donations_recurring_count);
      }

      // If we have donations, use that
      if (this.donationsRecurring) {
        return this.donationsRecurring.length;
      }

      return 0;
    },
    totalCountTrackedSingleDonations() {
      const min = this.intentionMeta.tracked_donation_minimum;
      return this.donations.filter((d) => d.amount >= min && d.recurring == false).length;
    },
    totalCountTrackedRecurringDonations() {
      const min = this.intentionMeta.tracked_donation_minimum;
      return this.donations.filter((d) => d.amount >= min && d.recurring == true).length;
    },
    donationsSingle() {
      return this.donations.filter(this.filterSingle);
    },
    donationsRecurring() {
      return this.donations.filter(this.filterRecurring);
    },
    totalRemaining() {
      return Number(this.targets.all) - this.totalDollars;
    },
    totalRemainingSingle() {
      return Number(this.targets.single) - this.totalDollarsSingle;
    },
    totalRemainingRecurring() {
      return Number(this.targets.recurring) - this.totalDollarsRecurring;
    },
  },
  methods: {
    /**
     * If it is recurring... if this is the first occurrence, then count it for
     * 12 months. If its not the first occurrence, then don't count.
     * Weird. Yes, I know.
     *
     * @param donation
     * @returns {boolean}
     */
    filterOldRecurring(donation) {
      if (!donation.recurring) {
        return true;
      }

      return donation.occurrence === 1;
    },
    filterSingle(donation) {
      return donation.recurring === false;
    },
    filterRecurring(donation) {
      return donation.recurring === true;
    },
    formatAmountStat(value, format) {
      // console.log('hasStatistics.js methods formatAmountStat() :value :format, { value, format });
      format = format || AmountFormat;
      return numeral(value).format(format);
    },
    formatNumberStat(value, format) {
      // console.log('hasStatistics.js methods formatNumberStat() :value :format, { value, format });
      format = format || NumberFormat;
      return value >= 1000 ? numeral(value).format(format) : value;
    },
    formatPercentStat(value, format) {
      // console.log('hasStatistics.js methods formatPercentStat() :value :format, { value, format });
      format = format || PercentFormat;
      return numeral(value).format(format);
    },
    formatStatByIntentionCounterType(value) {
      // console.log('hasStatistics.js methods formatStatByIntentionCounterType() :value', value);
      return this.intention.countable === 'amount' || this.intention.countable === 'match'
        ? this.formatAmountStat(value)
        : this.formatNumberStat(value);
    },
    formatBarLabelByIntentionCounterType(value) {
      // console.log('hasStatistics.js methods formatBarLabelByIntentionCounterType() :value', value);
      const { tracked_donation_type } = this.intentionMeta;
      const { countable } = this.intention;
      if (tracked_donation_type === 'single' || tracked_donation_type === 'recurring') {
        return this.formatNumberStat(value);
      }

      return countable === 'amount' || countable === 'match'
        ? this.formatAmountStat(value)
        : this.formatNumberStat(value);
    },
    donationsAverage(type, format) {
      type = '' || type;
      format = format || AmountFormat;
      let average = 0;

      if (type !== 'all' && this.statistics) {
        average = this.statistics.total_avg;

        if (type === 'single') {
          average = this.statistics.total_single_avg;
        } else if (type === 'recurring') {
          average = this.statistics.total_recurring_avg;
        }
      } else if (this.donations && this.donations.length) {
        average = _.meanBy(this.donations, (d) => (d.recurring ? d.total * 12 : d.total));

        if (type === 'single') {
          average = _.meanBy(
            this.donations.filter((d) => {
              return d.recurring == false;
            }),
            (d) => d.total
          );
        } else if (type === 'recurring') {
          average = _.meanBy(
            this.donations.filter((d) => {
              return d.recurring == true;
            }),
            (d) => d.total * 12
          );
        }
      }
      // console.log('hasStatistics.js methods donationsAverage()', { type, format, average });
      return this.formatAmountStat(average, format);
    },
    donationsPercentage(type, format) {
      type = '' || type;
      format = format || PercentFormat;

      let percent = 0;
      let sum = Number(this.statistics.total_sum) + Number(this.statistics.pledges_total_sum);
      let target = this.targets.all;

      if (type === 'single') {
        sum = this.totalDollarsSingle;
        target = this.targets.single;
      } else if (type === 'recurring') {
        sum = this.totalDollarsRecurring;
        target = this.targets.recurring;
      }

      if (sum && target) {
        percent = sum / target;
      }

      return this.formatPercentStat(percent, format);
    },
    averageMovement(type) {
      type = '' || type;
      let movement = 0;

      if (type === 'single') {
        if (
          this.statistics.total_donations_single_current_hour_avg >
          this.statistics.total_donations_single_previous_hour_avg
        ) {
          movement = 1;
        } else if (
          this.statistics.total_donations_single_current_hour_avg <
          this.statistics.total_donations_single_previous_hour_avg
        ) {
          movement = -1;
        }
      } else if (type === 'recurring') {
        if (
          this.statistics.total_donations_recurring_current_hour_avg >
          this.statistics.total_donations_recurring_previous_hour_avg
        ) {
          movement = 1;
        } else if (
          this.statistics.total_donations_recurring_current_hour_avg <
          this.statistics.total_donations_recurring_previous_hour_avg
        ) {
          movement = -1;
        }
      } else {
        if (
          this.statistics.total_donations_current_hour_avg >
          this.statistics.total_donations_previous_hour_avg
        ) {
          movement = 1;
        } else if (
          this.statistics.total_donations_current_hour_avg <
          this.statistics.total_donations_previous_hour_avg
        ) {
          movement = -1;
        }
      }

      if (movement === 1) {
        return 'text-success';
      } else if (movement === -1) {
        return 'text-danger';
      }

      return 'text-warning';
    },
    averageMovementIcon(movement) {
      if (movement === 'text-success') {
        return 'chevron-up';
      } else if (movement === 'text-danger') {
        return 'chevron-down';
      }

      return 'equals';
    },
  },
};

// leave the export, even if you don't use it
export default hasStatistics;
